import { VIEW_DETAILS_PAGE } from "./types";

export const handleViewDetailsPage = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_DETAILS_PAGE,
      payload: data,
    });
  } catch (error) {}
};

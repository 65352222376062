import React, { useState } from "react";
import { Select, Button, Modal, Tooltip } from "antd";

import UnionIcon from "../../assets/images/icons/Union_5.svg";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/calender.svg";

import Round from "../../assets/images/choose-setting/round.svg";
import Radiant from "../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../assets/images/choose-setting/Heart.svg";
import Oval from "../../assets/images/choose-setting/Oval.svg";
import Princess from "../../assets/images/choose-setting/Princess.svg";
import Pear from "../../assets/images/choose-setting/Radiant.svg";

import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";

import { useDispatch } from "react-redux";
import { handleViewDetailsPage } from "../../actions/chooseYourSetting/chooseYourSettingActions";

export const ChooseSettingItemDetails = () => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const images = [
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
  ];

  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "",
    personalMessage: "",
  });

  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [dropHintModal, setDropHintModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [scheduleViewingModal, setScheduleViewingModal] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);

  const [itemDetailsInputData, setItemDetailsInputData] = useState({
    metalType: "",
    centerStoneSize: "",
    ringSize: "",
  });

  const openRequestMoreModal = () => {
    setRequestMoreModal(true);
  };
  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
  };

  const openDropAHintModal = () => {
    setDropHintModal(true);
  };
  const closeDropHintModal = () => {
    setDropHintModal(false);
  };

  const openEmailFriendModal = () => {
    setEmailFriendModal(true);
  };
  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
  };

  const showModal4 = () => {
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const openScheduleViewingModal = () => {
    setScheduleViewingModal(true);
  };
  const closeScheduleViewingModal = () => {
    setScheduleViewingModal(false);
  };

  const showModal6 = () => {
    setIsModalVisible6(true);
  };
  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const handleBuySetting = () => {
    dispatch(handleViewDetailsPage(false));
  };

  const handleItemDetailsInputChange = (e, name) => {
    setItemDetailsInputData({
      ...itemDetailsInputData,
      [name]: e,
    });
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
  };

  const handleSubmitDropHint = () => {
    console.log(dropHintInputData);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });
  };

  const handleSubmitRequestMoreInfo = () => {
    console.log(requestMoreInfoInputData);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "",
      personalMessage: "",
    });
  };

  const handleSubmitEmailFriend = () => {
    console.log(emailFriendInputData);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
  };

  const handleSubmitSheduleViewing = () => {
    debugger;
    console.log(scheduleViewingInputData);
    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="row">
          <div className="col-md-12">
            <div className="prodetail__section">
              <div className="form__fields border-0">
                <div className="col-md-12">
                  <div className="prod__maindiv app__preview p-0 border-0">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="">
                              <p>Available Shape :</p>
                            </div>
                            <div className="whole__shapes available__shapes">
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Round} />
                                </div>
                                <p>Round</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Radiant} />
                                </div>
                                <p>Radiant</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Princess} />
                                </div>
                                <p>Princess</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Pear} />
                                </div>
                                <p>Pear</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Oval} />
                                </div>
                                <p>Oval</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Heart} />
                                </div>
                                <p>Heart</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Emerald} />
                                </div>
                                <p>Emerald</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Cushion} />
                                </div>
                                <p>Cushion</p>
                              </div>
                              <div className="shapes__block selected">
                                <div className="image">
                                  <img src={Asscher} />
                                </div>
                                <p>Asscher</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="thimage__slider">
                              <ImageGallery
                                items={images}
                                renderLeftNav={renderLeftNav}
                                renderRightNav={renderRightNav}
                              />
                              <div className="video__icon" onClick={showModal6}>
                                <i
                                  class="fa fa-video-camera"
                                  aria-hidden="true"
                                ></i>
                              </div>

                              <div className="share__block">
                                <div className="share__button">
                                  <img src={shareBlue} />
                                </div>
                                <div className="social__button">
                                  <img src={shareFacebook} />
                                  <img src={sharePintrest} />
                                  <img src={shareTwitter} />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                              <div className="price__block text-center">
                                  <h4 className="subheading">US$2,295</h4>
                                  <button className="primary-btn">Add To Cart</button>
                              </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-12">
                        <div className="prodesc__div">
                          <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="tab__div border-0"
                          >
                            <Tab eventKey="home" title="Items Details">
                              <div className="tab__contentdiv border__block p-0">
                                <div className="head__div">
                                  <h4>Engagement Rings Solitaires Any Shape</h4>
                                </div>
                                <div className="desc__maindiv">
                                  <div className="row top__content m-0">
                                    <div className="col-md-12 mt-3">
                                      <p>
                                        This 14K white gold product can
                                        accommodate a marquise diamond shape.
                                        Available in 14K, 18K white, yellow and
                                        rose gold, as well as Platinum. Center
                                        diamond not included. Matching wedding
                                        band sold separately.
                                      </p>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="input__block">
                                        <label className="subheading m-0">
                                          SKU# 83957-6X3-14KW-1
                                          <Tooltip
                                            placement="right"
                                            title="All metal color images may not be available"
                                          >
                                            <img src={UnionIcon} alt="" />
                                          </Tooltip>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="input__block">
                                        <label>Metal Type</label>
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={itemDetailsInputData.metalType}
                                          onChange={(e) =>
                                            handleItemDetailsInputChange(
                                              e,
                                              "metalType"
                                            )
                                          }
                                        >
                                          <Option value="male">Option 1</Option>
                                          <Option value="female">
                                            Option 2
                                          </Option>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="input__block">
                                        <label>Center Stone Size</label>
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={
                                            itemDetailsInputData.centerStoneSize
                                          }
                                          onChange={(e) =>
                                            handleItemDetailsInputChange(
                                              e,
                                              "centerStoneSize"
                                            )
                                          }
                                        >
                                          <Option value="male">Option 1</Option>
                                          <Option value="female">
                                            Option 2
                                          </Option>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="col-md-3">
                                      <div className="input__block">
                                        <label>Ring Size</label>
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={itemDetailsInputData.ringSize}
                                          onChange={(e) =>
                                            handleItemDetailsInputChange(
                                              e,
                                              "ringSize"
                                            )
                                          }
                                        >
                                          <Option value="male">Option 1</Option>
                                          <Option value="female">
                                            Option 2
                                          </Option>
                                        </Select>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="input__block">
                                        <label className="subheading m-0">
                                          US$320
                                          <Tooltip
                                            placement="right"
                                            title="Pricing is only for Engagement Ring Setting Shown.Any additional ring(s) featured in the product video are sold seperately"
                                          >
                                            <img src={UnionIcon} alt="" />
                                          </Tooltip>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        <button className="primary-btn mr-2">
                                          Add Your Diamond
                                        </button>
                                        <button
                                          className="primary-btn ml-1"
                                          onClick={handleBuySetting}
                                        >
                                          Buy Setting Only
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="desc__maindiv">
                                  <div className="row top__content m-0">
                                    <div className="itemdetails__btns">
                                      <p onClick={openDropAHintModal}>
                                        <img src={drophint} />
                                        <span>Drop A Hint</span>
                                      </p>
                                      <p onClick={openRequestMoreModal}>
                                        <img src={infoblack} />
                                        <span>Request More Info</span>
                                      </p>
                                      <p onClick={openEmailFriendModal}>
                                        <img src={emailicon} />
                                        <span>E-Mail A Friend</span>
                                      </p>
                                      <p onClick={openScheduleViewingModal}>
                                        <img src={calender} />
                                        <span>Schedule Viewing</span>
                                      </p>
                                      {/* <p onClick={showModal4}><img src={calender} /><span>Print Details</span></p> */}
                                    </div>
                                    <div className="col-md-12">
                                      <p>
                                        Have a question regarding this item? Our
                                        specialists are available to assist you.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="profile" title="Specifications">
                              <div className="tab__contentdiv border__block p-0">
                                <div className="head__div">
                                  <h4>Setting Details</h4>
                                </div>
                                <div className="desc__maindiv">
                                  <div className="desc__div">
                                    <span className="head__txt">
                                      Setting Number
                                    </span>
                                    <span className="desc__txt">
                                      F1619-.12-18KW-3
                                    </span>
                                  </div>
                                  <div className="desc__div">
                                    <span className="head__txt">Price</span>
                                    <span className="desc__txt">$NaN</span>
                                  </div>
                                  <div className="desc__div">
                                    <span className="head__txt">
                                      Metal Type{" "}
                                    </span>
                                    <span className="desc__txt">18K Gold</span>
                                  </div>
                                  <div className="desc__div">
                                    <span className="head__txt">
                                      Gemstone Type{" "}
                                    </span>
                                    <span className="desc__txt">Round</span>
                                  </div>
                                </div>

                                <div className="head__div">
                                  <h4>Can Be Set With</h4>
                                </div>
                                <div className="desc__maindiv">
                                  <div className="desc__div">
                                    <span className="head__txt">Round</span>
                                    <span className="desc__txt">0.11-0.13</span>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Section Starts */}

      {/* Request More Info Modal Starts */}
      <Modal
        title="Request More Info"
        visible={requestMoreModal}
        centered
        className="request_info"
        onCancel={closeRequestMoreModal}
        footer={[
          <Button key="back" onClick={closeRequestMoreModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmitRequestMoreInfo}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.name}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "name", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail Address</label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.email}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "email", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.phoneNumber}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "phoneNumber", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Contact Preference</label>
                    <div className="row">
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byEmail"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byEmail"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Email</label>
                      </div>
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byPhone"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byPhone"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Phone</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Write A Personal Message</label>
                    <textarea
                      value={requestMoreInfoInputData.personalMessage}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(
                          e,
                          "personalMessage",
                          "radio"
                        )
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Request More Info Modal Ends */}

      {/* Drop A Hint Modal Starts */}
      <Modal
        title="Drop A Hint"
        visible={dropHintModal}
        centered
        className="drop_a_hint"
        onCancel={closeDropHintModal}
        footer={[
          <Button key="back" onClick={closeDropHintModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmitDropHint}>
            Drop Hint
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      value={dropHintInputData.name}
                      onChange={(e) => handleDropHintChange(e, "name", "input")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail</label>
                    <input
                      type="text"
                      value={dropHintInputData.email}
                      onChange={(e) =>
                        handleDropHintChange(e, "email", "input")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Hint Recipient's Name</label>
                    <input
                      type="text"
                      value={dropHintInputData.recipientName}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientName", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Hint Recipient's E-Mail</label>
                    <input
                      type="text"
                      value={dropHintInputData.recipientEmail}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientEmail", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Reason For This Gift</label>
                    <input
                      type="text"
                      value={dropHintInputData.giftReason}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftReason", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Gift Deadline</label>
                    <input
                      type="date"
                      value={dropHintInputData.giftDeadline}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftDeadline", "date")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here</label>
                    <textarea
                      value={dropHintInputData.personalMessage}
                      onChange={(e) =>
                        handleDropHintChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Drop A Hint Modal Ends */}

      {/* E-Mail A Friend Modal Starts */}
      <Modal
        title="E-Mail A Friend"
        visible={emailFriendModal}
        centered
        className="email_a_friend"
        onCancel={closeEmailFriendModal}
        footer={[
          <Button key="back" onClick={closeEmailFriendModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmitEmailFriend}>
            Send To Friend
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      value={emailFriendInputData.name}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "name", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail</label>
                    <input
                      type="text"
                      value={emailFriendInputData.email}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "email", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Friend's Name</label>
                    <input
                      type="text"
                      value={emailFriendInputData.friendName}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendName", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Friend's E-Mail</label>
                    <input
                      type="text"
                      value={emailFriendInputData.friendEmail}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendEmail", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here</label>
                    <textarea
                      value={emailFriendInputData.personalMessage}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* E-Mail A Friend Modal Ends */}

      {/* Print Details Modal Starts */}
      <Modal
        title="Print Details"
        visible={isModalVisible4}
        centered
        className="print_details"
        onCancel={handleCancel4}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail Address</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Company Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Store Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Write A Personal Message</label>
                    <textarea></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Print Details Modal Ends */}

      {/* Schedule Viewing Modal Starts */}
      <Modal
        title="Schedule Viewing"
        centered
        visible={scheduleViewingModal}
        className="schedule_viewing"
        onCancel={closeScheduleViewingModal}
        footer={[
          <Button key="back" onClick={closeScheduleViewingModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmitSheduleViewing}
          >
            Schedule
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.name}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "name", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Email</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.email}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "email", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.phoneNumber}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "phoneNumber", "input")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Location</label>
                    <Select
                      className="border__grey"
                      name=""
                      optionFilterProp="children"
                      value={scheduleViewingInputData.location}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "location", "select")
                      }
                    >
                      <Option value="Option 1">Option 1 </Option>
                      <Option value="Option 2"> Option 2 </Option>
                    </Select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Appointment Date</label>
                    <input
                      type="date"
                      value={scheduleViewingInputData.appointmentDate}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentDate",
                          "date"
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Appointment Time</label>
                    <input
                      type="time"
                      value={scheduleViewingInputData.appointmentTime}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentTime",
                          "time"
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Address</label>
                    <textarea
                      value={scheduleViewingInputData.address}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "address", "input")
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Message Here ....</label>
                    <textarea
                      value={scheduleViewingInputData.message}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "message", "input")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Schedule Viewing Modal Ends */}

      {/* Thumbnail Slider Video Modal Starts */}
      <Modal
        title=""
        centered
        visible={isModalVisible6}
        className="video__modal"
        onCancel={handleCancel6}
        footer={false}
      >
        <div className="video__section">
          <video
            width="100%"
            height="80%"
            src="https://www.overnightmountings.com/gemfind/library/Images_And_Videos/F1626/F1626.video.white.mp4"
            autoPlay
            loop
            controls
          ></video>
        </div>
      </Modal>
      {/* Thumbnail Slider Video Modal Ends */}

      {/* Modal Section Ends */}
    </React.Fragment>
  );
};

export default ChooseSettingItemDetails;

import { VIEW_DETAILS_PAGE } from "../../actions/chooseYourSetting/types";

const initialState = {
  isViewDetails: false,
};

export default function handleChooseYourSetting(
  chooseYourSetting = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: payload,
      };

    default:
      return chooseYourSetting;
  }
}

import React, { useState, useEffect } from "react";

import SettingIcon from "../assets/images/icons/setting__icon.svg";
import DiamondIcon from "../assets/images/icons/diamond__icon.svg";
import RingIcon from "../assets/images/icons/ring__icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleActiveTab,
  handleSwapTabs,
} from "../actions/header/headerActions";
import ChooseYourSetting from "../component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "../component/ApplicationPreview/CompleteYourRing";
import { handleViewDetailsPage } from "../actions/chooseYourSetting/chooseYourSettingActions";

export const Header = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.headerReducer);
  const [activeTab, setActiveTab] = useState("chooseYourSetting");
  const [tabDetails, setTabDetails] = useState([]);

  const handleTabClick = (tab, index) => {
    debugger;
    let tabDetailsArray = tabDetails;
    if (index == tabDetailsArray.length - 1) {
      let temp = tabDetailsArray[0];
      tabDetailsArray[0] = tabDetailsArray[1];
      tabDetailsArray[1] = temp;
      dispatch(handleSwapTabs(tabDetailsArray));
    }
    dispatch(handleActiveTab(tab));
  };

  useEffect(() => {
    if (currentTab) {
      if (currentTab.activeTab) {
        setActiveTab(currentTab.activeTab);
        setTabDetails(currentTab.tabDetails);
      }
    }
  }, [currentTab]);

  return (
    // <div>
    //     <ul className="breadcrumb wizard">
    //         <li className="active">
    //             <NavLink to={"/"}>
    //                 <p className="mb-0"><span className="circle">1</span> Choose Your Setting</p>
    //                 <img src={SettingIcon} />
    //             </NavLink>
    //         </li>
    //         <li>
    //             <NavLink to={"/selectYourDiamond"}>
    //                 <p className="mb-0"><span className="circle">2</span>Select Your Diamond</p>
    //                 <img src={DiamondIcon} />
    //             </NavLink>
    //         </li>
    //         <li>
    //             <NavLink to={"/completeYourRing"}>
    //                 <p className="mb-0"><span className="circle">3</span> Complete Your Ring</p>
    //                 <img src={RingIcon} />
    //             </NavLink>
    //         </li>
    //     </ul>
    // </div>
    <>
      <div class="bcontainer">
        <div class="breadcrumb">
          {tabDetails &&
            tabDetails.map((tab, index) => {
              return (
                <li
                  className={activeTab == tab.tabValue && "active"}
                  onClick={() => handleTabClick(tab.tabValue, index)}
                >
                  <p className="mb-0">
                    <span className="circle"> {index + 1}</span> {tab.tabName}
                  </p>
                  <img src={tab.imgSrc} />
                </li>
              );
            })}

          {/* <li
            className={activeTab == "selectYourDiamond" && "active"}
            onClick={() => handleTabClick("selectYourDiamond")}
          >
            <p className="mb-0">
              <span className="circle">2</span>Select Your Diamond
            </p>
            <img src={DiamondIcon} />
          </li> */}

          <li
            className={activeTab == "completeYourRing" && "active"}
            onClick={() => handleTabClick("completeYourRing")}
          >
            <p className="mb-0">
              <span className="circle">3</span> Complete Your Ring
            </p>
            <img src={RingIcon} />
          </li>
        </div>
      </div>
      {activeTab == "chooseYourSetting" && <ChooseYourSetting />}
      {activeTab == "selectYourDiamond" && <SelectYourDiamond />}
      {activeTab == "completeYourRing" && <CompleteYourRing />}
    </>
  );
};

export default Header;

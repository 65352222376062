import { PropertySafetyFilled } from "@ant-design/icons";
import React, { useState, Fragment } from "react";
import Header from "./Header";

export const Layout = (props) => {
  const [isChooseCss, setIsChooseCss] = useState(false);
  const [hoverColor, setHoverColor] = useState("");
  const [columnHeaderAccentColor, setColumnHeaderAccentColor] = useState("");
  const [linkColor, setLinkColor] = useState("");
  const [callToActionColor, setCallToActionColor] = useState("");
  const handleChooseCss = () => {
    setIsChooseCss(!isChooseCss);
  };

  const handleColorChange = (e, name) => {
    if (name == "hover") {
      setHoverColor(e.target.value);
    }
    if (name == "columnHeader") {
      setColumnHeaderAccentColor(e.target.value);
    }
    if (name == "linkColor") {
      setLinkColor(e.target.value);
    }
    if (name == "ctaColor") {
      setCallToActionColor(e.target.value);
    }
  };

  const handleResetColor = () => {
    setHoverColor("");
    setColumnHeaderAccentColor("");
    setLinkColor("");
    setCallToActionColor("");
  };
  return (
    <Fragment>
      <div className="form__fields m-2 iframe__block">
        <div className="top__navigation">
          <Header />
        </div>
        <div>{props.children}</div>

        <div
          className={
            isChooseCss
              ? "slidebar__section__open slidebar__section"
              : "slidebar__section__close slidebar__section"
          }
        >
          <button
            onClick={handleChooseCss}
            className="primary-btn slidebar__btn m-0"
          >
            <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
          <div className="right__sidebar">
            <div className="font__block">
              <p className="font__bold">Choose Font</p>
              <div className="font__options">
                <p>
                  <span>Font option1</span> <input type="radio" />
                </p>
                <p>
                  <span>Font option2</span> <input type="radio" />
                </p>
                <p>
                  <span>Font option3</span> <input type="radio" />
                </p>
                <p>
                  <span>Font option4</span> <input type="radio" />
                </p>
                <p>
                  <span>Font option5</span> <input type="radio" />
                </p>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Hover Effect</p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000022" />
                  <p>#000022</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={hoverColor}
                    onChange={(e) => handleColorChange(e, "hover")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "hover")}
                      value={hoverColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Column Header Accent</p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000000" />
                  <p>#000000</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={columnHeaderAccentColor}
                    onChange={(e) => handleColorChange(e, "columnHeader")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "columnHeader")}
                      value={columnHeaderAccentColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Link Color</p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#828282" />
                  <p>#828282</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={linkColor}
                    onChange={(e) => handleColorChange(e, "linkColor")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "linkColor")}
                      value={linkColor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="colorselection__block">
              <p className="font__bold">Call to Action Button</p>
              <div className="color__section">
                <div className="left__block w-50">
                  <input type="color" value="#000022" />
                  <p>#000022</p>
                </div>
                <div className="right__block w-50">
                  <input
                    type="color"
                    value={callToActionColor}
                    onChange={(e) => handleColorChange(e, "ctaColor")}
                  />
                  <div className="input__block mb-0">
                    <input
                      type="text"
                      onChange={(e) => handleColorChange(e, "ctaColor")}
                      value={callToActionColor}
                    />
                  </div>
                </div>
              </div>
              <div className="sidebar__btn mt-4">
                <button className="primary-btn mr-2">Save</button>
                <button className="primary-btn" onClick={handleResetColor}>
                  Reset
                </button>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;

import { SET_ACTIVE_TAB, SWAP_TABS } from "../../actions/header/types";
import SettingIcon from "../../assets/images/icons/setting__icon.svg";
import DiamondIcon from "../../assets/images/icons/diamond__icon.svg";
const initialState = {
  activeTab: "chooseYourSetting",
  tabDetails: [
    {
      tabName: "Choose Your Setting",
      tabValue: "chooseYourSetting",
      imgSrc: SettingIcon,
    },
    {
      tabName: "Select Your Diamond",
      tabValue: "selectYourDiamond",
      imgSrc: DiamondIcon,
    },
  ],
};

export default function handleHeader(headerState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return {
        ...headerState,
        activeTab: payload,
      };
    case SWAP_TABS:
      return {
        ...headerState,
        tabDetails: payload,
      };

    default:
      return headerState;
  }
}

import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Slider,
  Dropdown,
  Button,
  Space,
  Tooltip,
} from "antd";
import Filter_icon from "../../assets/images/filter_icon.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Info_icon from "../../assets/images/info_icon.png";
import Eye_icon from "../../assets/images/eye__icon.svg";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import Create_icon from "../../assets/images/create_icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import space__icon from "../../assets/images/space__icon.png";
import Close from "../../assets/images/close.png";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
// import MultiRangeSlider from "../contacts/MultiRangeSlider";

import Bypass__img from "../../assets/images/choose-setting/Bypass__img.png";
import fancy__shape from "../../assets/images/choose-setting/fancy__shape.png";
import Halo__img from "../../assets/images/choose-setting/Halo__img.png";
import MultiRow from "../../assets/images/choose-setting/MultiRow__img.png";
import Pave from "../../assets/images/choose-setting/Pave.png";
import SingleRow from "../../assets/images/choose-setting/singleRow.png";
import Solitaire from "../../assets/images/choose-setting/Solitaire.png";
import ThreeStone from "../../assets/images/choose-setting/ThreeStone.png";
import Trellis from "../../assets/images/choose-setting/Trellis.png";
import Vintage from "../../assets/images/choose-setting/Vintage.png";

import Round from "../../assets/images/choose-setting/round.svg";
import Radiant from "../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../assets/images/choose-setting/Oval.svg";
import Princess from "../../assets/images/choose-setting/Princess.svg";
import Pear from "../../assets/images/choose-setting/Radiant.svg";
import ChooseSettingItemDetails from "./ChooseSettingItemDetails";
import { useSelector, useDispatch } from "react-redux";
import { handleViewDetailsPage } from "./../../actions/chooseYourSetting/chooseYourSettingActions";

const ChooseYourSetting = () => {
  const [productChange, setProductChange] = useState(true);
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [settingFilter, setSettingFilter] = useState({
    priceMin: 0,
    priceMax: 9999999,
    shapes: [],
    ringType: [],
  });
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [chooseYourSettingTableFilters, setChooseYourSettingTableFilters] =
    useState({
      recordsPerPage: 0,
      price: "lowHigh",
      search: "",
    });

  const dispatch = useDispatch();
  const chooseYourSetting = useSelector(
    (state) => state.chooseYourSettingReducer
  );

  const gender = (
    <span>
      Gender is not a required field for vendor data, so by leaving this blank,
      the majority of product results will be considered Ladies.
    </span>
  );

  const collection = (
    <span>Please select a vendor before choosing a collection.</span>
  );

  const columns = [
    {
      title: "",
      dataIndex: "checkinpt",
      width: 30,
    },
    {
      title: "",
      dataIndex: "image",
      width: 60,
    },
    {
      title: "Retail Stock #",
      dataIndex: "retailstock",
      width: 300,
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
    },
    {
      title: "Collection",
      dataIndex: "collection",
    },
    {
      title: "$ cost",
      dataIndex: "cost",
    },
    {
      title: "$ Retail",
      dataIndex: "retail",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img src={Info_icon} alt="" />
          </div>
          <div className="image__block">
            <img src={Video_icon} alt="" />
          </div>
          <div className="image__block" onClick={handleClickToView}>
            <img src={Eye_icon} alt="" />
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "2",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "3",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "4",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;

  useEffect(() => {
    if (chooseYourSetting) {
      if (chooseYourSetting.isViewDetails) {
        setIsViewDetails(chooseYourSetting.isViewDetails);
      }
    }
  }, []);

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const handleProductChangeOpen = () => {
    setProductChange(false);
  };

  const handleProductChangeClose = () => {
    setProductChange(true);
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };
  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    var Value = e.target.value && e.target.value.replace("$", "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > settingFilter.priceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleSettingFilterChange = (e, name) => {
    debugger;
    if (name == "shapes") {
      let shapes = settingFilter.shapes;
      if (shapes.toString().includes(e)) {
        shapes.pop(e);
      } else {
        shapes.push(e);
      }

      setSettingFilter({
        ...settingFilter,
        [name]: shapes,
      });
    }
    if (name == "ringType") {
      let ringType = settingFilter.ringType;
      if (ringType.toString().includes(e)) {
        ringType.pop(e);
      } else {
        ringType.push(e);
      }

      setSettingFilter({
        ...settingFilter,
        [name]: ringType,
      });
    }
  };

  const handleClickToView = () => {
    dispatch(handleViewDetailsPage(!isViewDetails));
  };

  const handleTableChange = (e, name, type) => {
    if (type == "select") {
      setChooseYourSettingTableFilters({
        ...chooseYourSettingTableFilters,
        [name]: e,
      });
    } else {
      setChooseYourSettingTableFilters({
        ...chooseYourSettingTableFilters,
        [name]: e.target.value,
      });
    }
  };

  return (
    <React.Fragment>
      {isViewDetails ? (
        <ChooseSettingItemDetails />
      ) : (
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row">
            <div className="col-md-12">
              <div className="filter_section">
                <div className="col-md-12">
                  <div class="img__block ring__type">
                    <div
                      class={
                        settingFilter.ringType
                          ? settingFilter.ringType.toString().includes("Bypass")
                            ? "imgselct__div selected"
                            : "imgselct__div"
                          : "imgselct__div"
                      }
                      onClick={() =>
                        handleSettingFilterChange("Bypass", "ringType")
                      }
                    >
                      <img src={Bypass__img} />
                      <p>Bypass</p>
                    </div>
                    <div class="imgselct__div selected">
                      <img src={fancy__shape} />
                      <p>Fancy Shape</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={Halo__img} />
                      <p>Halo</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={MultiRow} />
                      <p>MultiRow</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={Pave} />
                      <p>Pave</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={SingleRow} />
                      <p>Single Row</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={Solitaire} />
                      <p>Solitaire</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={ThreeStone} />
                      <p>Three Stone</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={Trellis} />
                      <p>Trellis</p>
                    </div>
                    <div class="imgselct__div">
                      <img src={Vintage} />
                      <p>Vintage</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form__fields filter_inpsection border-0">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label>
                              {" "}
                              Shape
                              <Tooltip
                                placement="right"
                                title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            {/* <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Gender"
                                                        optionFilterProp="children"
                                                        onChange={onChange}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="male">All</Option>
                                                        <Option value="female">Child(0)</Option>
                                                        <Option value="female">Ladies(2344)</Option>
                                                        <Option value="female">Mens(1234)</Option>
                                                        <Option value="female">Teen(4)</Option>
                                                        <Option value="female">Unisex(2172)</Option>
                                                        <Option value="female">Womens(5576)</Option>
                                                    </Select> */}
                          </div>
                        </div>
                        <div className="whole__shapes col-lg-12">
                          <div
                            className={
                              settingFilter.shapes
                                ? settingFilter.shapes
                                    .toString()
                                    .includes("Round")
                                  ? "shapes__block selected"
                                  : "shapes__block"
                                : "shapes__block"
                            }
                            onClick={() =>
                              handleSettingFilterChange("Round", "shapes")
                            }
                          >
                            <div className="image">
                              <img src={Round} />
                            </div>
                            <p>Round</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Radiant} />
                            </div>
                            <p>Radiant</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Princess} />
                            </div>
                            <p>Princess</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Pear} />
                            </div>
                            <p>Pear</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Oval} />
                            </div>
                            <p>Oval</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Marquise} />
                            </div>
                            <p>Marquise</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Heart} />
                            </div>
                            <p>Heart</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Emerald} />
                            </div>
                            <p>Emerald</p>
                          </div>
                          <div className="shapes__block">
                            <div className="image">
                              <img src={Cushion} />
                            </div>
                            <p>Cushion</p>
                          </div>
                          <div className="shapes__block selected">
                            <div className="image">
                              <img src={Asscher} />
                            </div>
                            <p>Asscher</p>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="sliderrange__maindiv">
                            <label> {"Price"} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={priceValue}
                                min={settingFilter.priceMin}
                                max={settingFilter.priceMax}
                                onChange={(e) => {
                                  setPriceValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      "$" +
                                      priceValue[0].toLocaleString("en-US")
                                    }
                                    onChange={(e) => {
                                      firstSliderPriceValue(e, 0);
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      "$" +
                                      priceValue[1].toLocaleString("en-US")
                                    }
                                    onChange={(e) => {
                                      firstSliderPriceValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="input__block">
                            <label>
                              {" "}
                              Color
                              <Tooltip
                                placement="right"
                                title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                          </div>
                          <div className="whole__shapes color__block">
                            <div className="shapes__block">
                              <div className="image rose__metal">
                                <span>14K</span>
                              </div>
                              <p>Rose Metal</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image white__gold">
                                <span>14K</span>
                              </div>
                              <p>White Gold</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image yellow__gold">
                                <span>14K</span>
                              </div>
                              <p>Yellow Gold</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image rose__metal">
                                <span>18K</span>
                              </div>
                              <p>Rose Metal</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image white__gold">
                                <span>18K</span>
                              </div>
                              <p>White Gold</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image yellow__gold">
                                <span>18K</span>
                              </div>
                              <p>Yellow Gold</p>
                            </div>
                            <div className="shapes__block">
                              <div className="image white__gold">
                                <span>Platinum</span>
                              </div>
                              <p>Platinum</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-5">
                  <div className="filt_optndiv">
                    <div className="gview__div">
                      <span className="font__bold mr-2">
                        4,418 Similar Diamonds
                      </span>
                      <span
                        className={
                          viewItem === "grid"
                            ? "fa fa-th grid_icon active"
                            : "fa fa-th grid_icon"
                        }
                        onClick={() => handleViewChange("grid")}
                      ></span>
                      <span
                        className={
                          viewItem === "list"
                            ? "fa fa-th-list list_icon active"
                            : "fa fa-th-list list_icon"
                        }
                        onClick={() => handleViewChange("list")}
                      ></span>

                      <span className="font__bold ml-2">Compare Items (4)</span>
                    </div>

                    <div className="drpdwn__div">
                      <span className="mr-2 font__bold">Records Per Page </span>
                      <Select
                        className="select_inpt"
                        placeholder="10"
                        onChange={(e) =>
                          handleTableChange(e, "recordsPerPage", "select")
                        }
                      >
                        <option value="12">12</option>
                        <option value="11">11</option>
                        <option value="14">14</option>
                        <option value="16">16</option>
                      </Select>
                    </div>

                    <div className="drpdwn__div">
                      <span className="mr-2 font__bold">Price </span>
                      <Select
                        className="select_inpt"
                        placeholder="Low - High"
                        onChange={(e) =>
                          handleTableChange(e, "price", "select")
                        }
                      >
                        <option value="lowHigh">Low - High</option>
                        <option value="highLow">High - Low</option>
                      </Select>
                    </div>

                    <div className="input__block mb-0">
                      <input
                        type="search"
                        placeholder="Search Diamond Stock #"
                        onChange={(e) =>
                          handleTableChange(e, "search", "input")
                        }
                      />
                    </div>
                  </div>

                  <hr className="mt-4 mb-4" />
                </div>

                {viewItem === "grid" && (
                  <div className="col-md-12">
                    {/* <hr className="mt-4 mb-4" /> */}
                    <div className="prod__section">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="prod__maindiv">
                            <div className="chkbx__div">
                              <input type="checkbox" />
                            </div>
                            <div className="prodimg__div">
                              <img src={Rectangle} alt="" />
                            </div>
                            <div className="details__div">
                              <span className="style__txt">
                                14K Yellow Gold 5x7 Oval Australian Opal Doublet
                                Pendant
                              </span>
                              <span className="price__txt mt-4">$190.00</span>
                            </div>
                            <div className="editdel__div">
                              <div className="video__icon">
                                <img src={Video_icon} alt="" />
                              </div>
                            </div>
                            {productChange ? (
                              <div
                                className="info__div"
                                onClick={handleProductChangeOpen}
                              >
                                <img src={Info_icon} alt="" />
                              </div>
                            ) : (
                              <div className="descr__div">
                                <div>
                                  <p>
                                    <span>Retaile Stock #</span>
                                    <span>POD267ADXCI</span>
                                  </p>
                                  <p>
                                    <span>Category</span>
                                    <span>Pendants, Gemstone Pendant</span>
                                  </p>
                                  <p>
                                    <span>Collection</span>
                                    <span>Opal</span>
                                  </p>
                                  <p>
                                    <span>Vendor</span>
                                    <span>Parlé Jewelry Design</span>
                                  </p>
                                  <p>
                                    <span>Cost</span>
                                    <span>$95.00</span>
                                  </p>
                                  <p>
                                    <span>Retail</span>
                                    <span>$190.00</span>
                                  </p>
                                  <p>
                                    <span>Metal Type</span>
                                    <span>14K Yellow Gold Price</span>
                                  </p>
                                  <p>
                                    <span>Metal Color</span>
                                    <span>Yellow</span>
                                  </p>
                                </div>
                                <div
                                  className="close__div"
                                  onClick={handleProductChangeClose}
                                >
                                  <img src={Close} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="prod__maindiv">
                            <div className="chkbx__div">
                              <input type="checkbox" />
                            </div>
                            <div className="prodimg__div">
                              <img src={Rectangle} alt="" />
                            </div>
                            <div className="details__div">
                              <span className="style__txt">
                                14K Yellow Gold 5x7 Oval Australian Opal Doublet
                                Pendant
                              </span>
                              <span className="price__txt mt-4">$190.00</span>
                            </div>
                            <div className="editdel__div">
                              <div className="video__icon">
                                <img src={Video_icon} alt="" />
                              </div>
                            </div>
                            {productChange ? (
                              <div
                                className="info__div"
                                onClick={handleProductChangeOpen}
                              >
                                <img src={Info_icon} alt="" />
                              </div>
                            ) : (
                              <div className="descr__div">
                                <div>
                                  <p>
                                    <span>Retaile Stock #</span>
                                    <span>POD267ADXCI</span>
                                  </p>
                                  <p>
                                    <span>Category</span>
                                    <span>Pendants, Gemstone Pendant</span>
                                  </p>
                                  <p>
                                    <span>Collection</span>
                                    <span>Opal</span>
                                  </p>
                                  <p>
                                    <span>Vendor</span>
                                    <span>Parlé Jewelry Design</span>
                                  </p>
                                  <p>
                                    <span>Cost</span>
                                    <span>$95.00</span>
                                  </p>
                                  <p>
                                    <span>Retail</span>
                                    <span>$190.00</span>
                                  </p>
                                  <p>
                                    <span>Metal Type</span>
                                    <span>14K Yellow Gold Price</span>
                                  </p>
                                  <p>
                                    <span>Metal Color</span>
                                    <span>Yellow</span>
                                  </p>
                                </div>
                                <div
                                  className="close__div"
                                  onClick={handleProductChangeClose}
                                >
                                  <img src={Close} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="prod__maindiv">
                            <div className="chkbx__div">
                              <input type="checkbox" />
                            </div>
                            <div className="prodimg__div">
                              <img src={Rectangle} alt="" />
                            </div>
                            <div className="details__div">
                              <span className="style__txt">
                                14K Yellow Gold 5x7 Oval Australian Opal Doublet
                                Pendant
                              </span>
                              <span className="price__txt mt-4">$190.00</span>
                            </div>
                            <div className="editdel__div">
                              <div className="video__icon">
                                <img src={Video_icon} alt="" />
                              </div>
                            </div>
                            {productChange ? (
                              <div
                                className="info__div"
                                onClick={handleProductChangeOpen}
                              >
                                <img src={Info_icon} alt="" />
                              </div>
                            ) : (
                              <div className="descr__div">
                                <div>
                                  <p>
                                    <span>Retaile Stock #</span>
                                    <span>POD267ADXCI</span>
                                  </p>
                                  <p>
                                    <span>Category</span>
                                    <span>Pendants, Gemstone Pendant</span>
                                  </p>
                                  <p>
                                    <span>Collection</span>
                                    <span>Opal</span>
                                  </p>
                                  <p>
                                    <span>Vendor</span>
                                    <span>Parlé Jewelry Design</span>
                                  </p>
                                  <p>
                                    <span>Cost</span>
                                    <span>$95.00</span>
                                  </p>
                                  <p>
                                    <span>Retail</span>
                                    <span>$190.00</span>
                                  </p>
                                  <p>
                                    <span>Metal Type</span>
                                    <span>14K Yellow Gold Price</span>
                                  </p>
                                  <p>
                                    <span>Metal Color</span>
                                    <span>Yellow</span>
                                  </p>
                                </div>
                                <div
                                  className="close__div"
                                  onClick={handleProductChangeClose}
                                >
                                  <img src={Close} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="prod__maindiv">
                            <div className="chkbx__div">
                              <input type="checkbox" />
                            </div>
                            <div className="prodimg__div">
                              <img src={Rectangle} alt="" />
                            </div>
                            <div className="details__div">
                              <span className="style__txt">
                                14K Yellow Gold 5x7 Oval Australian Opal Doublet
                                Pendant
                              </span>
                              <span className="price__txt mt-4">$190.00</span>
                            </div>
                            <div className="editdel__div">
                              <div className="video__icon">
                                <img src={Video_icon} alt="" />
                              </div>
                            </div>
                            {productChange ? (
                              <div
                                className="info__div"
                                onClick={handleProductChangeOpen}
                              >
                                <img src={Info_icon} alt="" />
                              </div>
                            ) : (
                              <div className="descr__div">
                                <div>
                                  <p>
                                    <span>Retaile Stock #</span>
                                    <span>POD267ADXCI</span>
                                  </p>
                                  <p>
                                    <span>Category</span>
                                    <span>Pendants, Gemstone Pendant</span>
                                  </p>
                                  <p>
                                    <span>Collection</span>
                                    <span>Opal</span>
                                  </p>
                                  <p>
                                    <span>Vendor</span>
                                    <span>Parlé Jewelry Design</span>
                                  </p>
                                  <p>
                                    <span>Cost</span>
                                    <span>$95.00</span>
                                  </p>
                                  <p>
                                    <span>Retail</span>
                                    <span>$190.00</span>
                                  </p>
                                  <p>
                                    <span>Metal Type</span>
                                    <span>14K Yellow Gold Price</span>
                                  </p>
                                  <p>
                                    <span>Metal Color</span>
                                    <span>Yellow</span>
                                  </p>
                                </div>
                                <div
                                  className="close__div"
                                  onClick={handleProductChangeClose}
                                >
                                  <img src={Close} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {viewItem === "list" && (
                  <div className="col-md-12 mt-4">
                    <div className="lviewprod__tbl">
                      <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ y: 300 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ChooseYourSetting;

import { SET_ACTIVE_TAB, SWAP_TABS } from "./types";

export const handleActiveTab = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: data,
    });
  } catch (error) {}
};

export const handleSwapTabs = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SWAP_TABS,
      payload: data,
    });
  } catch (error) {}
};

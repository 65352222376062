import React, { useState } from "react";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import SaveIcon from "../../assets/images/choose-setting/save__icon.svg";
import ResetIcon from "../../assets/images/choose-setting/reset__icon.svg";

import MinedDiamond from "./../common/SelectYourDiamond/MinedDiamond";

import LabGrownDiamond from "../common/SelectYourDiamond/LabGrown";
import Compare from "../common/SelectYourDiamond/Compare";

const SelectYourDiamond = () => {
  const [activeTab, setActiveTab] = useState("Mined");
  const [isMinedReset, setIsMinedReset] = useState(false);
  const [isMinedSaveSearch, setIsMinedSaveSearch] = useState(false);
  const [isLabGrownReset, setIsLabGrownReset] = useState(false);
  const [isLabGrownSaveSearch, setIsLabGrownSaveSearch] = useState(false);

  const handleTabChange = (e) => {
    setActiveTab(e);
  };

  const handleReset = () => {
    if (activeTab == "Mined") {
      setIsMinedReset(true);
    } else {
      setIsLabGrownReset(true);
    }
  };

  const handleGetReset = (reset) => {
    if (activeTab == "Mined") {
      setIsMinedReset(reset);
    } else {
      setIsLabGrownReset(reset);
    }
  };

  const handleSaveSearch = () => {
    if (activeTab == "Mined") {
      setIsMinedSaveSearch(true);
    } else {
      setIsLabGrownSaveSearch(true);
    }
  };

  const handleGetSaveSearch = (saveSearch) => {
    if (activeTab == "Mined") {
      setIsMinedSaveSearch(saveSearch);
    } else {
      setIsLabGrownSaveSearch(saveSearch);
    }
  };

  return (
    <React.Fragment>
      <span className="back__btn"> {"<< Back To Item Details"} </span>

      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="row">
          <div className="col-md-12">
            <div className="prodetail__section">
              <div className="form__fields border-0">
                <div className="col-md-12">
                  <div className="prod__maindiv app__preview p-0 border-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="prodesc__div">
                          <div className="tright__btns">
                            <p onClick={handleSaveSearch}>
                              <img src={SaveIcon} />
                              <span>Save Search</span>
                            </p>
                            <p onClick={handleReset}>
                              <img src={ResetIcon} />
                              <span>Reset</span>
                            </p>
                          </div>
                          <Tabs
                            defaultActiveKey="Mined"
                            id="uncontrolled-tab-example"
                            className="tab__div border-0"
                            onSelect={handleTabChange}
                          >
                            <Tab eventKey="Mined" title="Mined">
                              <MinedDiamond
                                isMinedReset={isMinedReset}
                                isMinedSaveSearch={isMinedSaveSearch}
                                handleGetReset={handleGetReset}
                                handleGetSaveSearch={handleGetSaveSearch}
                              />
                            </Tab>

                            <Tab eventKey="LabGrown" title="Lab Grown">
                              <LabGrownDiamond
                                isLabGrownReset={isLabGrownReset}
                                isLabGrownSaveSearch={isLabGrownSaveSearch}
                                handleGetReset={handleGetReset}
                                handleGetSaveSearch={handleGetSaveSearch}
                              />
                            </Tab>

                            <Tab eventKey="Compare" title="Compare">
                              <Compare />
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectYourDiamond;

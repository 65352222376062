import React from "react";
import roundDiamond from "../../../assets/images/daimond-link.jpg";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

const Compare = () => {
  return (
    <div className="tabs__content border__block">
      <div className="diamond__details filter_section">
        <div className="row">
          <div className="col-md-12">
            <div className="compare__table custom__table">
              <table class="table  table-striped">
                {/* <thead>
                                                                        <tr>
                                                                          <td></td>
                                                                          <td><img src={roundDiamond} width="50px" height="50px" /></td>
                                                                          <td><img src={roundDiamond} width="50px" height="50px" /></td>
                                                                        </tr>
                                                                      </thead> */}
                <tbody>
                  <tr>
                    <th></th>
                    <td>
                      <img src={roundDiamond} width="50px" height="50px" />
                    </td>
                    <td>
                      <img src={roundDiamond} width="50px" height="50px" />
                    </td>
                    <td>
                      <img src={roundDiamond} width="50px" height="50px" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Shape</span>
                      </div>
                    </th>
                    <td>Round</td>
                    <td>Emerald</td>
                    <td>Round</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>#SKU</span>
                      </div>
                    </th>
                    <td>55577854</td>
                    <td>95577854</td>
                    <td>55577854</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Carat</span>
                      </div>
                    </th>
                    <td>0.30</td>
                    <td>0.26</td>
                    <td>0.30</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Table</span>
                      </div>
                    </th>
                    <td>57.30</td>
                    <td>60</td>
                    <td>57.30</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Polish</span>
                      </div>
                    </th>
                    <td>Excellent</td>
                    <td>Excellent</td>
                    <td>Very Good</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Symmetry</span>
                      </div>
                    </th>
                    <td>Excellent</td>
                    <td>Excellent</td>
                    <td>Very Good</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Clarity</span>
                      </div>
                    </th>
                    <td>D</td>
                    <td>F</td>
                    <td>E</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Fluorescence</span>
                      </div>
                    </th>
                    <td>None</td>
                    <td>None</td>
                    <td>None</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Depth</span>
                      </div>
                    </th>
                    <td>60%</td>
                    <td>62%</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Measurement</span>
                      </div>
                    </th>
                    <td>4.31*4.35*2.64</td>
                    <td>4.21*4.35*2.50</td>
                    <td>4.35*4.35*2.50</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Cert</span>
                      </div>
                    </th>
                    <td>IGI</td>
                    <td>GIA</td>
                    <td>IGI</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Cut</span>
                      </div>
                    </th>
                    <td>Ideal</td>
                    <td>-</td>
                    <td>Ideal</td>
                  </tr>
                  <tr>
                    <th>
                      <div className="headingtable">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>{" "}
                        <span>Price</span>
                      </div>
                    </th>
                    <td>$183</td>
                    <td>$378</td>
                    <td>$203</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div className="action__btns">
                        <div className="image__block">
                          <img src={Eye_icon} alt="" />
                        </div>
                        <div className="image__block">
                          <img src={Delete_iconbl} alt="" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="action__btns">
                        <div className="image__block">
                          <img src={Eye_icon} alt="" />
                        </div>
                        <div className="image__block">
                          <img src={Delete_iconbl} alt="" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="action__btns">
                        <div className="image__block">
                          <img src={Eye_icon} alt="" />
                        </div>
                        <div className="image__block">
                          <img src={Delete_iconbl} alt="" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="text__block">
              <p>
                Use your diamond comparison to save all the details of the
                diamonds you are considering and view them all together
              </p>
              <p>This makes it easy to choose the right diamond.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="image__block">
              <img src={roundDiamond} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compare;

import React, { useEffect, useState } from "react";
import { Select, Slider, Tooltip, Table } from "antd";

import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import space__icon from "../../../assets/images/space__icon.png";

import roundDiamond from "../../../assets/images/daimond-link.jpg";

import Round from "../../../assets/images/choose-setting/round.svg";
import Radiant from "../../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../../assets/images/choose-setting/Oval.svg";
import Princess from "../../../assets/images/choose-setting/Princess.svg";
import Pear from "../../../assets/images/choose-setting/Pear.svg";

import { useDispatch } from "react-redux";
import { handleActiveTab } from "../../../actions/header/headerActions";

import Rectangle from "../../../assets/images/Rectangle.png";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Close from "../../../assets/images/close.png";

const MinedDiamond = ({
  isMinedReset,
  handleGetReset,
  isMinedSaveSearch,
  handleGetSaveSearch,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [isAdvance, setIsAdvance] = useState(false);
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [caratValue, setCaratValue] = useState([0, 9999999]);
  const [minedDiamondFilter, setMinedDiamondFilter] = useState({
    caratMin: 0,
    caratMax: 9999999,
    priceMin: 0,
    priceMax: 9999999,
    shapes: [],
    cut: [],
    color: [],
    polish: [],
    fluorescence: [],
    symmetry: [],
    certificate: "",
  });
  const [minedTableFilter, setMinedTableFilter] = useState({
    recordsPerPage: 0,
    price: "lowHigh",
    search: "",
  });
  const [productChange, setProductChange] = useState(true);
  const [viewItem, setViewItem] = useState("list");

  useEffect(() => {
    if (isMinedReset) {
      reset();
    }
  }, [isMinedReset]);

  useEffect(() => {
    if (isMinedSaveSearch) {
      saveSearch();
    }
  }, [isMinedSaveSearch]);

  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    var Value = e.target.value && e.target.value.replace("$", "");

    if (
      Number(parseFloat(Value.replace(/,/g, ""))) > minedDiamondFilter.priceMax
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
      }
    }
  };
  const sliderCaratValue = (e, num) => {
    let newArr = [...caratValue];
    var Value = e.target.value && e.target.value.replace("$", "");

    if (
      Number(parseFloat(Value.replace(/,/g, ""))) > minedDiamondFilter.caratMax
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setCaratValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
        setCaratValue(newArr);
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setCaratValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
        setCaratValue(newArr);
      }
    }
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleAdvanceClick = () => {
    setIsAdvance(!isAdvance);
  };

  const handleDiamondFilterChange = (e, name) => {
    if (name == "certificate") {
      setMinedDiamondFilter({
        ...minedDiamondFilter,
        [name]: e,
      });
    } else {
      let tempArray = minedDiamondFilter[name];
      if (tempArray.toString().includes(e)) {
        tempArray.pop(e);
      } else {
        tempArray.push(e);
      }

      setMinedDiamondFilter({
        ...minedDiamondFilter,
        [name]: tempArray,
      });
    }
  };

  const handleBtnClick = (btnType) => {
    if (btnType == "setting") {
      dispatch(handleActiveTab("chooseYourSetting"));
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "checkinpt",
      width: 30,
    },
    {
      title: "",
      dataIndex: "image",
      width: 60,
    },
    {
      title: "Retail Stock #",
      dataIndex: "retailstock",
      width: 300,
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
    },
    {
      title: "Collection",
      dataIndex: "collection",
    },
    {
      title: "$ cost",
      dataIndex: "cost",
    },
    {
      title: "$ Retail",
      dataIndex: "retail",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block">
              <img src={Info_icon} alt="" />
            </div>
            <div className="image__block">
              <img src={Video_icon} alt="" />
            </div>
            <div className="image__block">
              <img src={Eye_icon} alt="" />
            </div>
          </div>
          <div className="info__tooltip d-none">
            <div className="close__div">
              <img src={Close} alt="" />
            </div>
            <p>
              <span>Depth</span>
              <span>100</span>
            </p>
            <p>
              <span>Table</span>
              <span>100</span>
            </p>
            <p>
              <span>Polish</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>9.80X7.56X</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "2",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "3",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "4",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },
  ];

  const handleProductChangeOpen = () => {
    setProductChange(false);
  };

  const handleProductChangeClose = () => {
    setProductChange(true);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const reset = () => {
    setMinedDiamondFilter({
      caratMin: 0,
      caratMax: 9999999,
      priceMin: 0,
      priceMax: 9999999,
      shapes: [],
      cut: [],
      color: [],
      polish: [],
      fluorescence: [],
      symmetry: [],
      certificate: "",
    });
    setCaratValue([0, 9999999]);
    setPriceValue([0, 9999999]);
    handleGetReset(false);
  };

  const saveSearch = () => {
    console.log(minedDiamondFilter);
    handleGetSaveSearch(false);
  };

  const handleTableChange = (e, name, type) => {
    if (type == "select") {
      setMinedTableFilter({
        ...minedTableFilter,
        [name]: e,
      });
    } else {
      setMinedTableFilter({
        ...minedTableFilter,
        [name]: e.target.value,
      });
    }
  };

  return (
    <div className="tabs__content">
      <div className="diamond__details">
        <div className="row border__block">
          <div className="col-md-9">
            <div className="row">
              <div className="form__fields filter_inpsection border-0">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="input__block">
                        <label>
                          {" "}
                          Shape
                          <Tooltip
                            placement="right"
                            title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                      </div>
                    </div>
                    <div className="whole__shapes col-lg-12">
                      <div
                        className={
                          minedDiamondFilter.shapes
                            ? minedDiamondFilter.shapes
                                .toString()
                                .includes("Round")
                              ? "shapes__block selected"
                              : "shapes__block"
                            : "shapes__block"
                        }
                        onClick={() =>
                          handleDiamondFilterChange("Round", "shapes")
                        }
                      >
                        <div className="image">
                          <img src={Round} />
                        </div>
                        <p>Round</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Radiant} />
                        </div>
                        <p>Radiant</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Princess} />
                        </div>
                        <p>Princess</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Pear} />
                        </div>
                        <p>Pear</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Oval} />
                        </div>
                        <p>Oval</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Marquise} />
                        </div>
                        <p>Marquise</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Heart} />
                        </div>
                        <p>Heart</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Emerald} />
                        </div>
                        <p>Emerald</p>
                      </div>
                      <div className="shapes__block">
                        <div className="image">
                          <img src={Cushion} />
                        </div>
                        <p>Cushion</p>
                      </div>
                      <div className="shapes__block selected">
                        <div className="image">
                          <img src={Asscher} />
                        </div>
                        <p>Asscher</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input__block">
                        <label>
                          {" "}
                          Cut
                          <Tooltip
                            placement="right"
                            title="Not to be confused with shape, a diamond’s cut rating tells you how well its proportions interact with light. By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is. Cut grading is usually not available for fancy shapes (any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in."
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                      </div>
                      <div className="selection__block row">
                        <div
                          className="col"
                          onClick={() =>
                            handleDiamondFilterChange("Ideal", "cut")
                          }
                        >
                          <div
                            className={
                              minedDiamondFilter.cut
                                .toString()
                                .includes("Ideal")
                                ? "selection__box selected"
                                : "selection__box"
                            }
                          ></div>
                          <p>Ideal</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>Excellent</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>Very Good</p>
                        </div>

                        <div className="col">
                          <div className="selection__box"></div>
                          <p>Good</p>
                        </div>

                        <div className="col">
                          <div className="selection__box"></div>
                          <p>Fair</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input__block">
                        <label>
                          {" "}
                          Color
                          <Tooltip
                            placement="right"
                            title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                      </div>
                      <div className="selection__block row">
                        <div
                          className="col"
                          onClick={() =>
                            handleDiamondFilterChange("D", "color")
                          }
                        >
                          <div
                            className={
                              minedDiamondFilter.color.toString().includes("D")
                                ? "selection__box selected"
                                : "selection__box"
                            }
                          ></div>
                          <p>D</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>E</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>F</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>G</p>
                        </div>

                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>H</p>
                        </div>
                        <div className="col">
                          <div className="selection__box selected"></div>
                          <p>I</p>
                        </div>
                        <div className="col">
                          <div className="selection__box"></div>
                          <p>J</p>
                        </div>
                        <div className="col">
                          <div className="selection__box"></div>
                          <p>K</p>
                        </div>
                        <div className="col">
                          <div className="selection__box"></div>
                          <p>L</p>
                        </div>
                        <div className="col">
                          <div className="selection__box"></div>
                          <p>M</p>
                        </div>
                        <div className="col">
                          <div className="selection__box"></div>
                          <p>N</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {" "}
                          Carats
                          <Tooltip
                            placement="right"
                            title="Carat is a unit of measurement to determine a diamond’s weight. Typically, a higher carat weight means a larger looking diamond, but that is not always the case. Look for the mm measurements of the diamond to determine its visible size."
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        {/* <input type="range" /> */}
                        <div className="col-md-12 mt-3">
                          <Slider
                            range
                            value={caratValue}
                            min={minedDiamondFilter.caratMin}
                            max={minedDiamondFilter.caratMax}
                            onChange={(e) => {
                              setCaratValue(e);
                            }}
                          />
                          <div className="rangeSlider__values">
                            <div className="input__block">
                              <input
                                type="text"
                                value={
                                  "$" + caratValue[0].toLocaleString("en-US")
                                }
                                onChange={(e) => {
                                  sliderCaratValue(e, 0);
                                }}
                              />
                            </div>
                            <div>
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="input__block">
                              <input
                                type="text"
                                value={
                                  "$" + caratValue[1].toLocaleString("en-US")
                                }
                                onChange={(e) => {
                                  sliderCaratValue(e, 1);
                                }}
                                onKeyPress={onKeyPressEvent1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input__block">
                        <label>
                          {" "}
                          Price Range
                          <Tooltip
                            placement="right"
                            title="This refer to different type of Price to filter and select the appropriate ring as per your requirements. Look for a best suit Price of your chosen ring."
                          >
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        {/* <input type="range" /> */}
                        <div className="col-md-12 mt-3">
                          <Slider
                            range
                            value={priceValue}
                            min={minedDiamondFilter.priceMin}
                            max={minedDiamondFilter.priceMax}
                            onChange={(e) => {
                              setPriceValue(e);
                            }}
                          />
                          <div className="rangeSlider__values">
                            <div className="input__block">
                              <input
                                type="text"
                                value={
                                  "$" + priceValue[0].toLocaleString("en-US")
                                }
                                onChange={(e) => {
                                  firstSliderPriceValue(e, 0);
                                }}
                              />
                            </div>
                            <div>
                              <img
                                src={space__icon}
                                className="img-fluid space__icon"
                              />
                            </div>
                            <div className="input__block">
                              <input
                                type="text"
                                value={
                                  "$" + priceValue[1].toLocaleString("en-US")
                                }
                                onChange={(e) => {
                                  firstSliderPriceValue(e, 1);
                                }}
                                onKeyPress={onKeyPressEvent1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="input__block d-flex align-item-center">
                        <button
                          className="primary-btn mr-1"
                          style={{
                            width: "25px",
                            height: "25px",
                          }}
                          onClick={handleAdvanceClick}
                        >
                          {isAdvance ? "-" : "+"}
                        </button>
                        <label>Advanced</label>
                      </div>
                    </div>

                    {isAdvance ? (
                      <>
                        <div className="col-md-12">
                          <div className="input__block">
                            <label>
                              {" "}
                              Polish
                              <Tooltip placement="right" title="">
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                          </div>
                          <div className="selection__block row">
                            <div className="col">
                              <div className="selection__box selected"></div>
                              <p>Excellent</p>
                            </div>

                            <div
                              className="col"
                              onClick={() =>
                                handleDiamondFilterChange("VeryGood", "polish")
                              }
                            >
                              <div
                                className={
                                  minedDiamondFilter.polish
                                    .toString()
                                    .includes("VeryGood")
                                    ? "selection__box selected "
                                    : "selection__box"
                                }
                              ></div>
                              <p>Very Good</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>Good</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>Fair</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input__block">
                            <label>
                              {" "}
                              Fluorescence
                              <Tooltip placement="right" title="">
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                          </div>
                          <div className="selection__block row">
                            <div className="col">
                              <div className="selection__box selected"></div>
                              <p>N</p>
                            </div>

                            <div className="col">
                              <div className="selection__box selected"></div>
                              <p>FNT</p>
                            </div>

                            <div
                              className="col"
                              onClick={() =>
                                handleDiamondFilterChange("Med", "fluorescence")
                              }
                            >
                              <div
                                className={
                                  minedDiamondFilter.fluorescence
                                    .toString()
                                    .includes("Med")
                                    ? "selection__box selected"
                                    : "selection__box"
                                }
                              ></div>
                              <p>Med</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>ST</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>VST</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input__block">
                            <label>
                              {" "}
                              Symmetry
                              <Tooltip placement="right" title="">
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                          </div>
                          <div className="selection__block row">
                            <div className="col">
                              <div className="selection__box selected"></div>
                              <p>Excellent</p>
                            </div>

                            <div
                              className="col"
                              onClick={() =>
                                handleDiamondFilterChange(
                                  "VeryGood",
                                  "symmetry"
                                )
                              }
                            >
                              <div
                                className={
                                  minedDiamondFilter.symmetry
                                    .toString()
                                    .includes("VeryGood")
                                    ? "selection__box  selected"
                                    : "selection__box"
                                }
                              ></div>
                              <p>Very Good</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>Good</p>
                            </div>

                            <div className="col">
                              <div className="selection__box"></div>
                              <p>Fair</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Certificates"
                              optionFilterProp="children"
                              onChange={(e) =>
                                handleDiamondFilterChange(e, "certificate")
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="male">Option 1</Option>
                              <Option value="female">Option 2</Option>
                            </Select>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="diamondimage__block">
              <h3 className="subheading">Diamond Preview</h3>
              <img src={roundDiamond} />
              <h4>US$2,295</h4>
              <p>Stock Number: 177782295</p>

              <div className="mb-3">
                <button
                  className="primary-btn"
                  onClick={() => handleBtnClick("setting")}
                >
                  Add Your Setting
                </button>
              </div>

              <div className="mb-3">
                <button className="primary-btn">Add To Compare</button>
              </div>

              <div className="mb-3">
                <button className="primary-btn">Select Diamond</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="filter_section">
          <div className="col-md-12 mt-3">
            <div className="filt_optndiv">
              <div className="gview__div">
                <span className="font__bold mr-2">4,418 Similar Diamonds</span>
                <span
                  className={
                    viewItem === "grid"
                      ? "fa fa-th grid_icon active"
                      : "fa fa-th grid_icon"
                  }
                  onClick={() => handleViewChange("grid")}
                ></span>
                <span
                  className={
                    viewItem === "list"
                      ? "fa fa-th-list list_icon active"
                      : "fa fa-th-list list_icon"
                  }
                  onClick={() => handleViewChange("list")}
                ></span>

                <span className="font__bold ml-2">Compare Items (4)</span>
              </div>

              <div className="drpdwn__div">
                <span className="mr-2 font__bold">Records Per Page </span>
                <Select
                  className="select_inpt"
                  placeholder="10"
                  onChange={(e) =>
                    handleTableChange(e, "recordsPerPage", "select")
                  }
                >
                  <option value="12">12</option>
                  <option value="11">11</option>
                  <option value="14">14</option>
                  <option value="16">16</option>
                </Select>
              </div>

              <div className="drpdwn__div">
                <span className="mr-2 font__bold">Price </span>
                <Select
                  className="select_inpt"
                  placeholder="Low - High"
                  onChange={(e) => handleTableChange(e, "price", "select")}
                >
                  <option value="lowHigh">Low - High</option>
                  <option value="highLow">High - Low</option>
                </Select>
              </div>

              <div className="input__block mb-0">
                <input
                  value={minedTableFilter.search}
                  type="search"
                  placeholder="Search Diamond Stock #"
                  onChange={(e) => handleTableChange(e, "search", "input")}
                />
              </div>
            </div>

            <hr className="mt-4 mb-4" />
          </div>
          {data.length > 0 ? (
            <>
              {viewItem === "grid" && (
                <div className="col-md-12">
                  {/* <hr className="mt-4 mb-4" /> */}
                  <div className="prod__section">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt mt-4">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div className="video__icon">
                              <img src={Video_icon} alt="" />
                            </div>
                          </div>
                          {productChange ? (
                            <div
                              className="info__div"
                              onClick={handleProductChangeOpen}
                            >
                              <img src={Info_icon} alt="" />
                            </div>
                          ) : (
                            <div className="descr__div">
                              <div>
                                <p>
                                  <span>Retaile Stock #</span>
                                  <span>POD267ADXCI</span>
                                </p>
                                <p>
                                  <span>Category</span>
                                  <span>Pendants, Gemstone Pendant</span>
                                </p>
                                <p>
                                  <span>Collection</span>
                                  <span>Opal</span>
                                </p>
                                <p>
                                  <span>Vendor</span>
                                  <span>Parlé Jewelry Design</span>
                                </p>
                                <p>
                                  <span>Cost</span>
                                  <span>$95.00</span>
                                </p>
                                <p>
                                  <span>Retail</span>
                                  <span>$190.00</span>
                                </p>
                                <p>
                                  <span>Metal Type</span>
                                  <span>14K Yellow Gold Price</span>
                                </p>
                                <p>
                                  <span>Metal Color</span>
                                  <span>Yellow</span>
                                </p>
                              </div>
                              <div
                                className="close__div"
                                onClick={handleProductChangeClose}
                              >
                                <img src={Close} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt mt-4">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div className="video__icon">
                              <img src={Video_icon} alt="" />
                            </div>
                          </div>
                          {productChange ? (
                            <div
                              className="info__div"
                              onClick={handleProductChangeOpen}
                            >
                              <img src={Info_icon} alt="" />
                            </div>
                          ) : (
                            <div className="descr__div">
                              <div>
                                <p>
                                  <span>Retaile Stock #</span>
                                  <span>POD267ADXCI</span>
                                </p>
                                <p>
                                  <span>Category</span>
                                  <span>Pendants, Gemstone Pendant</span>
                                </p>
                                <p>
                                  <span>Collection</span>
                                  <span>Opal</span>
                                </p>
                                <p>
                                  <span>Vendor</span>
                                  <span>Parlé Jewelry Design</span>
                                </p>
                                <p>
                                  <span>Cost</span>
                                  <span>$95.00</span>
                                </p>
                                <p>
                                  <span>Retail</span>
                                  <span>$190.00</span>
                                </p>
                                <p>
                                  <span>Metal Type</span>
                                  <span>14K Yellow Gold Price</span>
                                </p>
                                <p>
                                  <span>Metal Color</span>
                                  <span>Yellow</span>
                                </p>
                              </div>
                              <div
                                className="close__div"
                                onClick={handleProductChangeClose}
                              >
                                <img src={Close} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt mt-4">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div className="video__icon">
                              <img src={Video_icon} alt="" />
                            </div>
                          </div>
                          {productChange ? (
                            <div
                              className="info__div"
                              onClick={handleProductChangeOpen}
                            >
                              <img src={Info_icon} alt="" />
                            </div>
                          ) : (
                            <div className="descr__div">
                              <div>
                                <p>
                                  <span>Retaile Stock #</span>
                                  <span>POD267ADXCI</span>
                                </p>
                                <p>
                                  <span>Category</span>
                                  <span>Pendants, Gemstone Pendant</span>
                                </p>
                                <p>
                                  <span>Collection</span>
                                  <span>Opal</span>
                                </p>
                                <p>
                                  <span>Vendor</span>
                                  <span>Parlé Jewelry Design</span>
                                </p>
                                <p>
                                  <span>Cost</span>
                                  <span>$95.00</span>
                                </p>
                                <p>
                                  <span>Retail</span>
                                  <span>$190.00</span>
                                </p>
                                <p>
                                  <span>Metal Type</span>
                                  <span>14K Yellow Gold Price</span>
                                </p>
                                <p>
                                  <span>Metal Color</span>
                                  <span>Yellow</span>
                                </p>
                              </div>
                              <div
                                className="close__div"
                                onClick={handleProductChangeClose}
                              >
                                <img src={Close} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="prod__maindiv">
                          <div className="chkbx__div">
                            <input type="checkbox" />
                          </div>
                          <div className="prodimg__div">
                            <img src={Rectangle} alt="" />
                          </div>
                          <div className="details__div">
                            <span className="style__txt">
                              14K Yellow Gold 5x7 Oval Australian Opal Doublet
                              Pendant
                            </span>
                            <span className="price__txt mt-4">$190.00</span>
                          </div>
                          <div className="editdel__div">
                            <div className="video__icon">
                              <img src={Video_icon} alt="" />
                            </div>
                          </div>
                          {productChange ? (
                            <div
                              className="info__div"
                              onClick={handleProductChangeOpen}
                            >
                              <img src={Info_icon} alt="" />
                            </div>
                          ) : (
                            <div className="descr__div">
                              <div>
                                <p>
                                  <span>Retaile Stock #</span>
                                  <span>POD267ADXCI</span>
                                </p>
                                <p>
                                  <span>Category</span>
                                  <span>Pendants, Gemstone Pendant</span>
                                </p>
                                <p>
                                  <span>Collection</span>
                                  <span>Opal</span>
                                </p>
                                <p>
                                  <span>Vendor</span>
                                  <span>Parlé Jewelry Design</span>
                                </p>
                                <p>
                                  <span>Cost</span>
                                  <span>$95.00</span>
                                </p>
                                <p>
                                  <span>Retail</span>
                                  <span>$190.00</span>
                                </p>
                                <p>
                                  <span>Metal Type</span>
                                  <span>14K Yellow Gold Price</span>
                                </p>
                                <p>
                                  <span>Metal Color</span>
                                  <span>Yellow</span>
                                </p>
                              </div>
                              <div
                                className="close__div"
                                onClick={handleProductChangeClose}
                              >
                                <img src={Close} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {viewItem === "list" && (
                <div className="col-md-12 mt-4">
                  <div className="lviewprod__tbl">
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{ y: 300 }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div class="col-md-12">
              <div class="table__block filter_section">
                <h5 className="subheading">
                  {" "}
                  No Diamonds Listed In That Range{" "}
                </h5>
                <p>
                  {" "}
                  Please enter your contact information. One of our diamond
                  specialists will contact you and assist you with your needs.{" "}
                </p>
                <div class="row p-0 border-0 form__fields mh-auto">
                  <div class="col-md-6">
                    <div class="input__block">
                      <label> First Name </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input__block">
                      <label> Last Name </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input__block">
                      <label> Phone Number </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input__block">
                      <label> Email Address </label>
                      <input type="text" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="input__block">
                      <label> Comments </label>
                      <textarea></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="save__form">
                      <button class="primary-btn mr-2"> Request </button>
                      <button class="primary-btn"> Reset </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MinedDiamond;

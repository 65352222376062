import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            {/* <Route path="/" element={<ChooseYourSetting />} />
            <Route path="/selectYourDiamond" element={<SelectYourDiamond />} />
            <Route path="/completeYourRing" element={<CompleteYourRing />} />
            <Route
              path="/chooseSettingItemDetails"
              element={<ChooseSettingItemDetails />}
            /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
